import {
	BROADCAST_SOCKET_DEV_CONFIG,
	BROADCAST_SOCKET_PROD_CONFIG,
	SPORTSX_CONFIG
} from "_services/firebase/projectConfig";

const env = process.env.APP_ENV || "prod";

// Clevertap
const TEST_CT_ID = "TEST-K7R-W57-774Z";
const PROD_CT_ID = "R7R-W57-774Z";

const config = {
	dev: {
		FEED_API           : "https://dev-consumer-feed.rooter.io/graphql",
		BROADCAST_VIEWS    : "https://stage-broadcast-view-events.rooter.io/broadcastViewEvents",
		BROADCAST_URL      : "https://dev-broadcast.rooter.io/data",
		BROADCAST_DNS      : "https://dev-broadcast.rooter.io/",
		USER_API_URL       : "https://dev-user.api.rooter.io/",
		FEED_POPULATOR_URL : "https://dev-feed.rooter.io/",
		CONSUMER_FEED_URL  : "https://dev-consumer-feed.rooter.io/",
		BLOGS_API_URL      : "https://blogs.rooter.gg/wp-json/wp/v2/",
		GROOT_URL          : "https://dev-groot.rooter.io/",
		SEARCH_URL         : "https://search.rooter.io/",
		STUDIO_URL         : "https://stage-studio.rooter.io/",
		AUTH               : "https://dev-auth.rooter.io/",
		ENGAGEMENT         : "https://stage-engagement.rooter.io/",
		LIVESTREAM_URL     : "https://livestream.rooter.io/",
		PROD_ADMIN_SRV     : "https://product-admin-service.rooter.io/",
		CONTENT_UPLOAD     : "https://upload.rooter.io/",
		GAMING_URL         : "https://gaming.rooter.io/",
		ROOTER_PRO         : "https://stage-user.rooter.io/",
		SHOP_URL           : "https://dev-bazaar.rooter.io/",
		PAYMENTS           : "https://payments.rooter.io/",
		PAYMENTS_BRIDGE    : "https://stage-payments-bridge.rooter.io/",
		COUPONS            : "https://stage-coupons.rooter.io/",
		STREAM_URL         : "https://stream.rooter.io/",
		ROOTER_RTC         : "wss://stage-rtc.rooter.io/ws",
		CT_ID              : TEST_CT_ID,
		firebase           : {
			broadcast : BROADCAST_SOCKET_DEV_CONFIG,
			sportx    : SPORTSX_CONFIG
		}
	},
	stage: {
		FEED_API               : "https://stage-feed.rooter.io/graphql",
		BROADCAST_VIEWS        : "https://stage-broadcast-view-events.rooter.io/broadcastViewEvents",
		BROADCAST_URL          : "https://stage-broadcast.rooter.io/data",
		FALLBACK_FEED_API      : "https://fallback-service.rooter.io/graphql",
		FALLBACK_BROADCAST_URL : "https://fallback-service.rooter.io/data",
		BROADCAST_DNS          : "https://stage-broadcast.rooter.io/",
		USER_API_URL           : "https://semi-final.api.rooter.io/",
		USER_API_URL_GRAPHQL   : "https://semi-final.api.rooter.io/graphql",
		FEED_POPULATOR_URL     : "https://stage-feed-pop.rooter.io/",
		CONSUMER_FEED_URL      : "https://stage-feed.rooter.io/",
		BLOGS_API_URL          : "https://blogs.rooter.gg/wp-json/wp/v2/",
		GROOT_URL              : "https://stage-groot.rooter.io/",
		SEARCH_URL             : "https://search.rooter.io/",
		STUDIO_URL             : "https://stage-studio.rooter.io/",
		AUTH                   : "https://stage-auth.rooter.io/",
		ENGAGEMENT             : "https://stage-engagement.rooter.io/",
		LIVESTREAM_URL         : "https://livestream.rooter.io/",
		SHOP_URL               : "https://stage-bazaar.rooter.io/",
		PROD_ADMIN_SRV         : "https://product-admin-service.rooter.io/",
		CONTENT_UPLOAD         : "https://upload.rooter.io/",
		GAMING_URL             : "https://gaming.rooter.io/",
		ROOTER_PRO             : "https://stage-user.rooter.io/",
		PAYMENTS               : "https://stage-payments.rooter.io/",
		PAYMENTS_BRIDGE        : "https://stage-payments-bridge.rooter.io/",
		COUPONS                : "https://stage-coupons.rooter.io/",
		STREAM_URL             : "https://stream.rooter.io/",
		ROOTER_RTC             : "wss://stage-rtc.rooter.io/ws",
		CT_ID                  : TEST_CT_ID,
		firebase               : {
			broadcast : BROADCAST_SOCKET_PROD_CONFIG,
			sportx    : SPORTSX_CONFIG
		}
	},
	prod: {
		FEED_API               : "https://feed.rooter.io/graphql",
		BROADCAST_VIEWS        : "https://broadcast-prod-view-events.rooter.io/broadcastViewEvents",
		BROADCAST_URL          : "https://broadcast.api.rooter.io/data",
		FALLBACK_FEED_API      : "https://fallback-service.rooter.io/graphql",
		FALLBACK_BROADCAST_URL : "https://fallback-service.rooter.io/data",
		BROADCAST_DNS          : "https://broadcast.api.rooter.io/",
		USER_API_URL           : "https://user.api.rooter.io/",
		USER_API_URL_GRAPHQL   : "https://broadcast.api.rooter.io/data",
		FEED_POPULATOR_URL     : "https://feed-populator.rooter.io/",
		SHOP_URL               : "https://bazaar.rooter.io/",
		CONSUMER_FEED_URL      : "https://feed.rooter.io/",
		BLOGS_API_URL          : "https://blogs.rooter.gg/wp-json/wp/v2/",
		GROOT_URL              : "https://groot.rooter.io/rooter/",
		SEARCH_URL             : "https://search.rooter.io/",
		STUDIO_URL             : "https://rooter-studio.rooter.io/",
		AUTH                   : "https://auth.rooter.io/",
		ENGAGEMENT             : "https://engagement.rooter.io/",
		LIVESTREAM_URL         : "https://livestream.rooter.io/",
		PROD_ADMIN_SRV         : "https://product-admin-service.rooter.io/",
		CONTENT_UPLOAD         : "https://upload.rooter.io/",
		GAMING_URL             : "https://gaming.rooter.io/",
		ROOTER_PRO             : "https://user.rooter.io/",
		PAYMENTS               : "https://payments.rooter.io/",
		PAYMENTS_BRIDGE        : "https://payments-bridge.rooter.io/",
		COUPONS                : "https://coupons.rooter.io/",
		STREAM_URL             : "https://stream.rooter.io/",
		ROOTER_RTC             : "wss://rtc.rooter.io/ws",
		CT_ID                  : PROD_CT_ID,
		firebase               : {
			broadcast : BROADCAST_SOCKET_PROD_CONFIG,
			sportx    : SPORTSX_CONFIG
		}
	}
}[env];

export default config;
