// API endpoints
import configs from "./env_config";
export { configs };

// Namespace key for generating hash for CDN cache key
export const NAMESPACE = {
	desktop : "959280ca-71e6-4cd4-a6fd-336ce729a029",
	mobile  : "9ab1c9b5-1c26-470b-8e56-e0c822f28c0d"
};

// Google analytics
export const GA_ID = "G-WSQC1N0GHD";
export const GTM_ID = "GTM-58TQ9F6";

// App config
export const APP_VERSION = "1.0.5";
export const DEVICE_TYPE = "web";
export const SITE_URL = "https://www.rooter.gg";

// Apple & Google store links
export const ROOTER_APP_STORE_LINK = "https://apps.apple.com/in/app/rooter-live-gaming-sports/id1115677906";
export const ROOTER_PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.threesixteen.app&hl=en_IN&gl=US";
// Chat config
export const MAX_COMMENTS_LIMIT = 300;
export const COMMENT_TRIM_COUNT = 50;
export const DEBOUNCE_COMMENTS_IN_MS = 100;
export const THROTTLING_COMMENTS_IN_MS = 500;
export const DEBOUNCE_POLLS_DATA_IN_MS = 200;

export const FALLBACK_URL_ENABLE = true;

export const MAIN_SERVER_RETRY_TIME = 30; // in seconds

const adTags = {
	rooter : "https://pubads.g.doubleclick.net/gampad/live/ads?iu=/22991961619/live_stream_preroll_web&description_url=http%3A%2F%2Fwww.rooter.gg&tfcd=0&npa=0&sz=400x300%7C640x480&gdfp_req=1&output=vast&env=vp&unviewed_position_start=1&impl=s&correlator=",
	google : "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator="
};

export const VIDEO_AD_TAG_URL = adTags["rooter"];
export const ENABLE_VIDEO_ADS = true;

// telegram bot url
export const TELEGRAM_BOT_MOBILE_URL = "https://t.me/RooterAppBot";
export const TELEGRAM_BOT_WEB_URL = "https://web.telegram.org/k/#@RooterAppBot";

// Singular Links
export const SNG_BANNER_LINK = "https://rooter.sng.link/B46b2/ftj4/62np";
export const SNG_POPUP_LINK = "https://rooter.sng.link/B46b2/gef8/k996";
export const SNG_HEADER_LINK = "https://rooter.sng.link/B46b2/hvz5/7u6p";
export const SNG_STICKY_LINK = "https://rooter.sng.link/B46b2/lrno/g2oy";
export const SNG_ERROR_LINK = "https://rooter.sng.link/B46b2/48fr/7fji";
export const SNG_DOWNLOAD_LINK = "https://rooter.sng.link/B46b2/unb7/m5aj";
export const SNG_OPEN_IN_APP = "https://rooter.sng.link/B46b2/ihti?_dl=rooter%3A%2F%2F&_smtype=3&_p=";

// testing
//  22324807338 HealthEngine.web [300,250]
// '/6355419/Travel', [728, 90]
// '/22953003753/RooterTest', [300, 250],

// testing 2
// /21687718510/feed_native_test_unit', ['fluid'],

// feeds ad unit
// 22953003753/Home_Page_Feed_Native_Banner_Web, ['fluid'], "div-gpt-ad-1659097394921-0"

// chat add unit
// 21687718510/Native_ChatBanner_Web [ "fluid" ]

// carousel add unit
// '/21687718510/Home_Page_Carousel_Web', ['fluid'], 'div-gpt-ad-1659428762121-0'

const feedAdLight = {
	ADS_PLACEMENT_COUNT : 4,
	networkId           : "22953003753",
	adUnit              : "Home_Page_Feed_Native_Banner_Web",
	slotId              : "div-gpt-ad-1659097394921-0",
	sizes               : [
		[ "fluid" ]
	]
};
// /22953003753/Home_Page_Feed_Native_Banner_Web_DarkMode', ['fluid'], 'div-gpt-ad-1689316407592-0'
const feedAdDark = {
	ADS_PLACEMENT_COUNT : 4,
	networkId           : "22953003753",
	adUnit              : "Home_Page_Feed_Native_Banner_Web_DarkMode",
	slotId              : "div-gpt-ad-1689316407592-0",
	sizes               : [
		[ "fluid" ]
	]
};

// '/22953003753/RooterTest', ['fluid'], 'div-gpt-ad-1689657103627-0'
const testAdLight = {
	ADS_PLACEMENT_COUNT : 4,
	networkId           : "22953003753",
	adUnit              : "RooterTest",
	slotId              : "div-gpt-ad-1689657103627-0",
	sizes               : [
		[ "fluid" ]
	]
};

const testAdDark = testAdLight;

const myTestAdLight = {
	ADS_PLACEMENT_COUNT : 4,
	networkId           : "22324807338",
	adUnit              : "HealthEngine.web",
	slotId              : "div-gpt-ad-1659097394921-0",
	sizes               : [
		[ 300, 250 ]
	]
};

const myTestAdDark = myTestAdLight;

const chatAdLight = {
	networkId : "21687718510",
	adUnit    : "Native_ChatBanner_Web",
	slotId    : "div-gpt-ad-1659097394998-0",
	sizes     : [
		[ "fluid" ]
	]
};

const chatAdDark = chatAdLight;

const carouselAdLight = {
	networkId : "21687718510",
	adUnit    : "Home_Page_Carousel_Web",
	slotId    : "div-gpt-ad-1659428762121-0",
	sizes     : [
		[ "fluid" ]
	]
};

const carouselAdDark = carouselAdLight;

export const googleDFPAdsConfig = {
	feed     : { light: feedAdLight, dark: feedAdDark },
	testAd   : { light: testAdLight, dark: testAdDark },
	myTestAd : { light: myTestAdLight, dark: myTestAdDark },
	chat     : { light: chatAdLight, dark: chatAdDark },
	carousel : { light: carouselAdLight, dark: carouselAdDark }
};

export const MULTIPART_UPLOAD_LIMIT = 100;
export const CHUNK_SIZE = 50;
export const THREADS_LIMIT = 3;

export const DONATION_PAGE = "https://www.rooter.gg/donationPage";
