/*
 * Only write functions which are independent of client functions
 * Anything which depends on browser functions or can only work
 * in a browser will break the files which are using this file
 */

import { useMantineTheme } from "@mantine/core";
import { useMediaQuery }   from "@mantine/hooks";
import dayjs               from "dayjs";
import utc                 from "dayjs/plugin/utc";
import Cookies             from "js-cookie";
import { UAParser }        from "ua-parser-js";

import { MAIN_SERVER_RETRY_TIME, SITE_URL } from "_config/index";

const MINUTES_IN_HOURS    = 60;
const ENABLE_FALLBACK_URL = "enableFallbackURL";

export const isNumeric = val => {
	return /^-?\d+$/.test ( val );
};

export function important ( val ) {
	return `${ val } !important`;
}
export const formatToRupees = ( value, decimals = 2 ) => {
	const formatter      = new Intl.NumberFormat ( "en-US", {
		style                 : "currency",
		currency              : "INR",
		minimumFractionDigits : 0, // Ensure at least 0 decimal places.
		maximumFractionDigits : decimals // Maximum 2 decimal places.
		// These options are needed to round to whole numbers if that's what you want.
		// minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		// maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	} );
	const formattedValue = formatter.format ( Number ( value ) );

	if ( formattedValue.endsWith ( ".00" ) ) {
		// Remove the ".00" and return as an integer.
		return formattedValue.slice ( 0, -3 );
	}

	return formattedValue;

};

export const isImageURL = url => {
	const imgUrlExt = [ ".png", ".jpg", ".webp", ".jpeg" ];

	if ( url && url !== "" ) {
		const isImage = imgUrlExt.some ( ext => {
			return url.toLowerCase ().endsWith ( ext.toLowerCase () );
		} );

		return isImage;
	}

	return false;
};

export const isCreatorDashboardPage = ( { router } ) => {
	const checkPageRoute = page => {
		return isPageRoute ( { router, page } );
	};

	const res = checkPageRoute ( "creator-dashboard" ) || checkPageRoute ( "creator-dashboard/overview" ) || checkPageRoute ( "creator-dashboard/channel-analytics" ) || checkPageRoute ( "creator-dashboard/content" ) || checkPageRoute ( "creator-dashboard/earning" );

	return res;
};

export const shortenNumber = value => {

	let newValue = value;

	if ( value >= 1000000 ) newValue = parseFloat ( ( value / 1000000 ).toFixed ( 1 ) ) + "M";
	else if ( value >= 1000 ) {
		newValue = parseFloat ( ( value / 1000 ).toFixed ( 1 ) ) + "K";
	}

	return newValue;
};

export const createQueryParams = params => {
	let query = "";

	for ( const param of Object.keys ( params ) ) {
		query += `&${ param }=${ params[param] }`;
	}

	return query ? `?${ query }` : "";
};

export const getBaseURL = () => {
	if ( !checkIsBrowser () )
		return SITE_URL;

	return window.location.origin;
};

export const formatDateTimeToIST = currentTime => {
	var currentOffset = currentTime.getTimezoneOffset ();

	var ISTOffset = 330; // IST offset UTC +5:30

	var ISTTime = new Date ( currentTime.getTime () + ( ISTOffset + currentOffset ) * 60000 );

	return ISTTime;
};

dayjs.extend ( utc );

export function cleanTitle ( title ) {
	const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

	/* eslint-disable */
  title = title.replace(regex, "");
  title = title.replace(/[.,\/#!$%\^&\*;'|?@:{}="\-_`~()\\]/g, "");
  /* eslint-enable */

	let lastChar = title.charAt ( title.length - 1 );

	while ( lastChar === "?" || lastChar === " " ) {
		title    = title.substr ( 0, title.length - 1 );
		lastChar = title.charAt ( title.length - 1 );
	}
	title = title.split ( " " ).join ( "-" );

	return title || "";
}

export const convert_m3u8_todownloadformat = file => {
	let pos       = file.indexOf ( "rooter.io/" ) + 9;
	let temp_link = file.substr ( 0, pos ) + "/download" + file.substr ( pos );

	pos               = temp_link.indexOf ( "m3u8" );
	let download_link = temp_link.substr ( 0, pos ) + "mp4" + temp_link.substr ( pos + 4 );

	return download_link;
};

export const checkForFeatureSupport = feature => {
	switch ( feature ) {
		case "clipboard": {
			if ( navigator?.clipboard ) {
				return true;
			}

			return false;
		}

		default:
			break;
	}
};

export const filterEmptyObjects = ( key, arr ) => {
	if ( arr ) {
		return arr.filter ( item => {
			if ( key ) {
				if ( item && item[key] ) return item;
			} else {
				if ( item ) return item;
			}
		} );
	}

	return [];
};

export const getTypeOfVideo = item => {
	let m3u8, mp4;

	if ( item.media[0] && item.media[0].mediaType === "VIDEO" ) {
		if ( item.media[0].href.indexOf ( ".mp4" ) !== -1 ) {
			mp4 = item.media[0].href;
		}
		if ( item.media[0].href.indexOf ( ".m3u8" ) !== -1 ) {
			m3u8 = item.media[0].href;
		}
	}
	if ( item.media[1] && item.media[1].mediaType === "VIDEO" ) {
		if ( item.media[1].href.indexOf ( ".mp4" ) !== -1 ) {
			mp4 = item.media[1].href;
		}
		if ( item.media[1].href.indexOf ( ".m3u8" ) !== -1 ) {
			m3u8 = item.media[1].href;
		}
	}
	if ( item.media[2] && item.media[2].mediaType === "VIDEO" ) {
		if ( item.media[2].href.indexOf ( ".mp4" ) !== -1 ) {
			mp4 = item.media[2].href;
		}
		if ( item.media[2].href.indexOf ( ".m3u8" ) !== -1 ) {
			m3u8 = item.media[2].href;
		}
	}

	return {
		m3u8,
		mp4
	};
};

export function cleverTapPopUp () {
	const clevertap = window.clevertap;

	if ( typeof clevertap !== "undefined" ) {
		clevertap.notifications.push ( {
			titleText             : "Would you like to receive Push Notifications?",
			bodyText              : "Cricket news curated specially for you!",
			okButtonText          : "Sign me up!",
			rejectButtonText      : "No thanks",
			okButtonColor         : "#f28046",
			askAgainTimeInSeconds : 259200,
			serviceWorkerPath     : "/service-worker.js" // path to your custom service worker file
		} );
	}
}

export function checkForChrome () {
	if ( !checkIsBrowser () )
		return true;

	const isChrome = !!window.chrome && ( !!window.chrome.webstore || !!window.chrome.runtime );

	return isChrome;
}

/* export function checkForAppleDevice () {
  if ( !checkIsBrowser () ) return;
  const isSafari = /^((?!chrome|android).)*safari/i.test ( navigator.userAgent );
  const iOS      = ( /iPad|iPhone|iPod/.test ( navigator.userAgent ) && !window.MSStream ) || isSafari;

  return iOS;
} */

export function checkForFirefox () {
	const isFirefox = typeof InstallTrigger !== "undefined";

	return isFirefox;
}

export const getResponsiveImage = ( image, type = "" ) => {
	if ( !image || image.indexOf ( "/incoming/" ) === -1 )
		return image;

	let image_type;
	let dpr         = window.devicePixelRatio;
	const extension = "webp";

	if ( type ) {
		image_type = `${ type }.${ extension }`;
	} else if ( window.innerWidth >= "600" ) {
		image_type = `xxhdpi.${ extension }`;
	} else if ( dpr <= 1.5 ) {
		image_type = `mdpi.${ extension }`;
	} else if ( dpr <= 2 ) {
		image_type = `hdpi.${ extension }`;
	} else if ( dpr <= 3 ) {
		image_type = `hdpi.${ extension }`;
	} else if ( dpr <= 4 ) {
		image_type = `xhdpi.${ extension }`;
	} else {
		image_type = `xxhdpi.${ extension }`;
	}
	let arr    = image.split ( "/" );
	let imgSrc = arr[arr.length - 1];
	let pos    = imgSrc.indexOf ( "." );

	imgSrc = imgSrc.substr ( 0, pos );
	arr.splice ( arr.length - 2, 2, imgSrc, image_type );
	imgSrc = arr.join ( "/" );

	return imgSrc;
};

export const getServerResponsiveImage = ( image, type = "mdpi" ) => {
	if ( !image || image.indexOf ( "/incoming/" ) === -1 )
		return image;

	let image_type = `${ type }.webp`;
	let arr        = image.split ( "/" );
	let imgSrc     = arr[arr.length - 1];
	let pos        = imgSrc.indexOf ( "." );

	imgSrc = imgSrc.substr ( 0, pos );
	arr.splice ( arr.length - 2, 2, imgSrc, image_type );
	imgSrc = arr.join ( "/" );

	return imgSrc;
};

export const getOptImage = ( image, type ) => {
	return checkIsBrowser () ? getResponsiveImage ( image, type ) : getServerResponsiveImage ( image, type );
};

export const isPageRoute = ( { router, page } ) => {
	switch ( page ) {
		case "home":
			return router.pathname === "/";

		case "live":
			return router.pathname === "/live";

		case "growth-dashboard":
			return router.pathname === "/growth-dashboard";

		case "creator-dashboard/earning":
			return router.pathname === "/creator-dashboard/earning";

		case "creator-dashboard/overview":
			return router.pathname === "/creator-dashboard/overview";

		case "creator-dashboard/content":
			return router.pathname === "/creator-dashboard/content";

		case "creator-dashboard/channel-analytics":
			return router.pathname === "/creator-dashboard/channel-analytics";

		case "profile":
			return router.pathname === "/profile/[id]";

		case "stream":
			return router.pathname === "/stream/[id]";

		case "studio":
			return router.pathname === "/studio";

		case "video":
			return router.pathname === "/video/[id]";

		case "popout":
			return router.pathname === "/popOutChat/[id]";

		case "gaming-videos":
			return router.pathname === "/gaming-videos";

		case "game":
			return router.pathname.includes ( "/game/[...slug]" );

		case "activate":
			return router.pathname.includes ( "/activate" );

		case "notifications":
			return router.pathname.includes ( "/notifications" );

		case "results":
			return router.pathname.includes ( "/results" );

		case "creator-dashboard":
			return router.pathname.includes ( "/creator-dashboard" );

		case "reels":
			return router.pathname.includes ( "/reels/[id]" );

		case "donate":
			return router.pathname.includes ( "/donate" );

		case "pro":
			return router.pathname === "/pro";

		default:
			return false;
	}
};

export const redirectToPage = page => {
	let href = "";

	switch ( page ) {
		case "about-us":
			href = "https://pages.rooter.gg/about-us";
			break;

		case "privacy-policy":
			href = "https://pages.rooter.gg/privacy-policy";
			break;

		case "terms-of-service":
			href = "https://pages.rooter.gg/terms-of-service";
			break;

		case "blogs":
			href = "https://www.rooter.gg/blogs";
			break;
	}

	var a = document.createElement ( "a" );

	a.href = href;
	a.setAttribute ( "target", "_blank" );
	a.click ();
};

export const validURL = str => {
	var pattern = new RegExp ( "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", "i" ); // fragment locator

	return !!pattern.test ( str );
};

export const generateAsterisk = ( { size = 10 } ) => {
	return Array ( size ).fill ( "*" ).join ( "" );
};

export const convertMinutesToHHMM = ( { minutes, type = "+HH:MM" } ) => {
	if ( !minutes ) return "";

	let hrs, mins;

	hrs  = Math.floor ( minutes / MINUTES_IN_HOURS );
	mins = minutes % MINUTES_IN_HOURS;

	if ( type === "HHh MMm" ) {
		if ( !hrs && !mins ) return "0h 0m";
		if ( !hrs ) {
			return `${ mins }m`;
		}

		return `${ hrs }h ${ mins }m`;
	} else {
		if ( hrs < 10 ) hrs = "0" + hrs;

		if ( mins < 10 ) mins = "0" + mins;

		return `+ ${ hrs }:${ mins }`;
	}

};

// convert percentage to hexadecimal code (https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4)
// for example you want 40% transparent of this color #126bd5 you have to make this "#126bd5"+"66" = "#126bd566"

export const percentToHex = p => {
	p              = Math.max ( 0, Math.min ( 100, p ) ); // bound percent from 0 to 100
	const intValue = Math.round ( p / 100 * 255 ); // map percent to nearest integer (0 - 255)
	const hexValue = intValue.toString ( 16 ); // get hexadecimal representation

	return hexValue.padStart ( 2, "0" ).toUpperCase (); // format with leading 0 and upper case characters
};

export const removeJSSLogs = () => {
	/* eslint-disable */
  if (typeof window === "undefined") {
    const originalWarn = console.warn;

    console.warn = (...args) => {
      if (
        args[0] !==
        "Warning: [JSS] Rule is not linked. Missing sheet option \"link: true\"."
      ) {
        originalWarn(...args);
      }
    };
  }
  /* eslint-enable */
};

export const downloadUrl = url => {
	const anchor = document.createElement ( "a" );

	anchor.href     = url;
	anchor.download = url.split ( "/" ).pop ();
	document.body.appendChild ( anchor );
	anchor.click ();
	document.body.removeChild ( anchor );
};

export const getMediaBreakpointKey = () => {
	if ( !checkIsBrowser () )
		return "lg";

	let breakpointKey = "xs";
	const theme       = useMantineTheme ();

	const xs   = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.xs }px)` );
	const sm   = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.sm }px)` );
	const md   = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.md }px)` );
	const lg   = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.lg }px)` );
	const xl   = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.xl }px)` );
	const xxl  = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.xxl }px)` );
	const xxxl = useMediaQuery ( `(min-width: ${ theme.other.breakpoints.xxxl }px)` );

	if ( xs ) breakpointKey = "xs";
	if ( sm ) breakpointKey = "sm";
	if ( md ) breakpointKey = "md";
	if ( lg ) breakpointKey = "lg";
	if ( xl ) breakpointKey = "xl";
	if ( xxl ) breakpointKey = "xxl";
	if ( xxxl ) breakpointKey = "xxxl";

	return breakpointKey;
};

export const getVideoCardCountPerRow = () => {
	const videoCardCountPerRowMap = {
		xs   : 1,
		sm   : 2,
		md   : 3,
		lg   : 4,
		xl   : 5,
		xxl  : 5,
		xxxl : 6
	};

	return videoCardCountPerRowMap[getMediaBreakpointKey ()];

};

export function convertToSlug ( text = "" ) {
	return text.toLowerCase ()
		.replace ( /[^\w ]+/g, "" )
		.replace ( / +/g, "-" );
}

export function convertSlugToString ( slug ) {
	return slug.toLowerCase ().replaceAll ( "-", " " );
}

export function capitalizeString ( str ) {
	return str.charAt ( 0 ).toUpperCase () + str.slice ( 1 );
}

export function capitalizeWords ( str ) {
	let words = str.split ( " " );

	for ( let i = 0; i < words.length; i ++ ) {
		words[i] = words[i].charAt ( 0 ).toUpperCase () + words[i].slice ( 1 );
	}

	return words.join ( " " );
}

export const formattedVideoDuration = seconds => {
	try {
		if ( !seconds ) return "00:00";

		if ( seconds < 60 * 60 )
			return new Date ( seconds * 1000 ).toISOString ().slice ( 14, 19 );

		return new Date ( seconds * 1000 ).toISOString ().slice ( 11, 19 );
	} catch ( error ) {
		return "";
	}
};

export function removeKey ( originalMap, keyToRemove ) {
	const newMap = { ...originalMap };

	delete newMap[keyToRemove];

	return newMap;
}

// remove \n from given string
export const removeWhiteSpaces = ( str = "" ) => {
	try {
		return str.replace ( /\n/g, "" );
	} catch ( error ) {
		return str;
	}
};

function convertSecondsToHMS ( seconds ) {
	var hours            = Math.floor ( seconds / 3600 );
	var minutes          = Math.floor ( ( seconds % 3600 ) / 60 );
	var remainingSeconds = seconds % 60;

	return {
		hours   : hours,
		minutes : minutes,
		seconds : remainingSeconds
	};
}

export const converToVideoObjectTime = durationInSec => {
	const { hours, minutes, seconds } = convertSecondsToHMS ( durationInSec );

	try {
		if ( hours ) {
			return `PT${ hours }H${ minutes }M${ seconds }S`;
		} else if ( minutes ) {
			return `PT${ minutes }M${ seconds }S`;
		} else {
			return `PT${ seconds }S`;
		}

	} catch {
		//
	}
};

export const checkIsBrowser = () => {
	const isSSR = typeof window === "undefined";

	return !isSSR;
};

export const enableBackupAPIsInCookies = () => {

	const currentDate = new Date ();

	currentDate.setSeconds ( currentDate.getSeconds () + MAIN_SERVER_RETRY_TIME );

	Cookies.set ( ENABLE_FALLBACK_URL, true, {
		expires  : currentDate,
		sameSite : "None",
		secure   : true
	} );
};

export const checkFallbackURLEnabled = () => {
	return Cookies.get ( ENABLE_FALLBACK_URL );
};

export const getDeviceType = userAgent => {
	if ( !userAgent )
		return null;

	let parser = new UAParser ( userAgent );
	const type = parser?.getDevice ()?.type;

	if ( type === "tablet" || type === "mobile" )
		return type;

	return "desktop";
};

export const isAppleDevice = () => {
	if ( !checkIsBrowser () )
		return;

	const isSafari = /^((?!chrome|android).)*safari/i.test ( navigator.userAgent );
	const iOS      = /iPad|iPhone|iPod/.test ( navigator.userAgent ) || isSafari;

	return iOS;
};

export const getBarHeight = () => {
	const actualHeight  = typeof window === "undefined" ? "100vh" : window?.innerHeight;
	const elementHeight = typeof window === "undefined" ? 0 : document.getElementById ( "control-height" )?.clientHeight || 0;
	const barHeight     = elementHeight - actualHeight || 0;

	return barHeight;
};

export function getThemeFromLocalStorage () {
	try {
		const isDarkMode = JSON.parse ( localStorage.getItem ( "darkMode" ) );

		return isDarkMode ? "dark" : "light";
	} catch ( error ) {
		return "light";
	}
}

export function readAsArrayBufferAsync ( file ) {
	return new Promise ( ( resolve, reject ) => {
		const reader = new FileReader ();

		reader.onload = event => {
			resolve ( event.target.result );
		};

		reader.onerror = error => {
			reject ( error );
		};

		reader.readAsArrayBuffer ( file );
	} );
}

export const delayedFunction = async ( { seconds = 1 } ) => {
	return new Promise ( resolve => {
		setTimeout ( () => {
			resolve ();
		}, seconds * 1000 );
	} );
};

export const combineArraysFromObjects = ( array, key ) => {
	return array.reduce ( ( acc, obj ) => {
		if ( Array.isArray ( obj[key] ) ) {
			return acc.concat ( obj[key] );
		}

		return acc;
	}, [] );
};

export const reloadPage = () => {
	if ( checkIsBrowser () )
		window.location.reload ();
};

export const redirectTo = url => {
	if ( checkIsBrowser () )
		window.location.replace ( url );
};

export const LIVESESSION = "LIVESESSION";
export const VIDEO = "VIDEO";
export const REEL = "REEL";
export const _REEL = "REEL";
export const maxWidth = "2000";

export const createDownloadFile = ( blob, filename ) => {
	// Create a link element
	const link = document.createElement ( "a" );
	const url  = window.URL.createObjectURL ( blob );

	link.href = url;

	// Set the download attribute with a filename
	link.download = filename;

	// Append the link to the body
	document.body.appendChild ( link );

	// Programmatically click the link to trigger the download
	link.click ();

	// Remove the link from the document
	document.body.removeChild ( link );

	// Revoke the object URL to free up memory
	window.URL.revokeObjectURL ( url );
};
